import Vue from 'vue';
import VueRouter from 'vue-router';
import CheckPermissions from '../checkPermissions.js';
import CheckEnv from '../checkEnv.js';

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      meta: { layout: 'login' },
      props: true,
      component: () => import('../views/Login.vue'),
      beforeEnter: (to, from, next) => {
        const token = Vue.$cookies.get('token');
        if (token) {
          next('/profile');
        } else {
          next();
        }
      },
    },
    {
      path: '/profile',
      name: 'profile',
      meta: { layout: 'default', requiresAuth: true },
      component: () => import('../views/Profile.vue'),
    },
    {
      path: '/admin',
      name: 'admin',
      meta: { permission: ['scope.administration_dashboard'], requiresAuth: true },
      component: () => import('../layouts/AdminLayout.vue'),
      children: [
        {
          path: 'agencies',
          name: 'agencies',
          meta: { permission: ['scope.administration_dashboard', 'agency.read_list'], requiresAuth: true },
          component: () => import('../views/Agencies.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
          }),
        },
        {
          path: 'channels',
          name: 'channels',
          meta: { permission: ['scope.administration_dashboard', 'channel.read_list'], requiresAuth: true },
          component: () => import('../views/Channels.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
          }),
        },
        {
          path: 'salehouses',
          name: 'salehouses',
          meta: { permission: ['scope.administration_dashboard', 'saleshouse.read_list'], requiresAuth: true },
          component: () => import('../views/SaleHouses.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
          }),
        },
        {
          path: 'users',
          name: 'users',
          meta: { permission: ['scope.administration_dashboard', 'user.read_list'], requiresAuth: true },
          component: () => import('../views/Users.vue'),
          props: (route) => ({
            agency_id: route.query.agency_id,
            channel_id: route.query.channel_id,
            sub_channel_id: route.query.sub_channel_id,
            saleshouse_id: route.query.saleshouse_id,
            role_id: route.query.role_id,
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
            show_deleted: route.query.show_deleted,
            search_type: route.query.search_type,
          }),
        },
        {
          path: 'advertisers',
          name: 'advertisers',
          meta: { permission: ['scope.administration_dashboard', 'advertiser.read_list'], requiresAuth: true },
          component: () => import('../views/Advertisers.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            agency_id: route.query.agency_id,
            brand_id: route.query.brand_id,
            sort_mode: route.query.sort,
          }),
        },
        {
          path: 'subchannels',
          name: 'subchannels',
          meta: { permission: ['scope.administration_dashboard', 'sub_channel.read_list'], requiresAuth: true, requiresEnv: ['ENABLE_SUBCHANNELS'] },
          component: () => import('../views/Subchannels.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            agency_id: route.query.agency_id,
            brand_id: route.query.brand_id,
            sort_mode: route.query.sort,
          }),
        },
        {
          path: 'classes',
          name: 'classes',
          meta: { permission: ['scope.administration_dashboard', 'brand_class.read_list'], requiresAuth: true },
          component: () => import('../views/Classes.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
          }),
        },
        {
          path: 'measurements',
          name: 'measurements',
          meta: { permission: ['scope.administration_dashboard', 'measurement_company.read_list'], requiresAuth: true },
          component: () => import('../views/Measurements.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
          }),
        },
        {
          path: 'genres',
          name: 'genres',
          meta: { permission: ['scope.administration_dashboard', 'genre.read_list'], requiresAuth: true },
          component: () => import('../views/Genres.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
          }),
        },
        {
          path: 'programs',
          name: 'programs',
          meta: { permission: ['scope.administration_dashboard', 'program.read_list'], requiresAuth: true },
          component: () => import('../views/Programs.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
            genre_id: route.query.genre_id,
          }),
        },
        {
          path: 'commVerTypes',
          name: 'commVerTypes',
          meta: { permission: ['scope.administration_dashboard', 'commercial_version_type.read_list'], requiresAuth: true },
          component: () => import('../views/CommercialVersionTypes.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
          }),
        },
        {
          path: 'discountTypes',
          name: 'discountTypes',
          meta: { permission: ['scope.administration_dashboard', 'discount_type.read_list'], requiresAuth: true },
          component: () => import('../views/DiscountTypes.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
            selected_year: route.query.year,
            discount_type: route.query.discount_type,
          }),
        },
        {
          path: 'premiumPositionDiscounts',
          name: 'premiumPositionDiscounts',
          meta: { permission: ['scope.administration_dashboard', 'premium_position_discount.read_list'], requiresAuth: true },
          component: () => import('../views/PremiumPositionDiscounts.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
            selected_year: route.query.year,
          }),
        },
        {
          path: 'commercials',
          name: 'commercials',
          meta: { permission: ['scope.administration_dashboard', 'commercial.read_list'], requiresAuth: true },
          component: () => import('../views/Commercials.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
            advertiser_id: route.query.advertiser_id,
            brand_id: route.query.brand_id,
            search_type: route.query.search_type,
          }),
        },
        {
          path: 'placementProhibition',
          name: 'placementProhibition',
          meta: { permission: ['scope.administration_dashboard', 'placement_prohibition.read_list'], requiresAuth: true },
          component: () => import('../views/PlacementProhibition.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
            brand_group_id: route.query.brand_group_id,
            genre_id: route.query.genre_id,
            selected_type: route.query.selected_type,
          }),
        },
        {
          path: 'coPlacementProhibition',
          name: 'coPlacementProhibition',
          meta: { permission: ['scope.administration_dashboard', 'co_placement_prohibition.read_list'], requiresAuth: true },
          component: () => import('../views/CoPlacementProhibition.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
            brand_group_id_1: route.query.brand_group_id_1,
            brand_group_id_2: route.query.brand_group_id_2,
          }),
        },
        {
          path: 'price-projects',
          name: 'priceProjects',
          meta: { permission: ['scope.administration_dashboard', 'price_project.read_list'], requiresAuth: true },
          component: () => import('../views/PriceProjects.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
            date_from: route.query.date_from,
            date_to: route.query.date_to,
            selected_type: route.query.selected_type,
            channel_id: route.query.channel_id,
          }),
        },
        {
          path: 'miscellaneous',
          name: 'miscellaneous',
          meta: { permission: ['global_stop.disable'], requiresAuth: true },
          component: () => import('../views/AdminMiscellaneous.vue'),
        },
        {
          path: 'logs',
          name: 'logs',
          meta: { permission: ['scope.administration_dashboard', 'scope.logs'], requiresAuth: true },
          component: () => import('../views/AdminLogsView.vue'),
          props: (route) => ({
            per_page: route.query.per_page,
            type: route.query.type,
            ts_from: +route.query.ts_from || undefined,
            ts_to: +route.query.ts_to || undefined,
            request_id: route.query.request_id,
            user_id: +route.query.user_id || undefined,
            user_login: route.query.user_login,
            user_name: route.query.user_name,
            user_email: route.query.user_email,
            spot_id: +route.query.spot_id || undefined,
            block_id: +route.query.block_id || undefined,
          }),
        },
        {
          path: 'holdings',
          name: 'holdings',
          meta: { permission: ['scope.administration_dashboard', 'holding.read_list'], requiresAuth: true, requiresEnv: ['ENABLE_HOLDINGS'] },
          component: () => import('../views/Holdings.vue'),
          props: (route) => ({
            q: route.query.q,
            per_page: route.query.per_page,
            on_page: route.query.on_page,
            sort_mode: route.query.sort,
          }),
        },
        {
          path: 'upload-retro-scale',
          name: 'uploadRetroScale',
          meta: { permission: ['scope.administration_dashboard', 'retro_bonus_scale.read'], requiresAuth: true, requiresEnv: ['ENABLE_RETROBONUS'] },
          component: () => import('../views/UploadRetroScale.vue'),
          props: (route) => ({
            holding_id: route.query.holding_id,
            year: route.query.year,
            advertiser_id: route.query.advertiser_id,
          }),
        },
      ], //End of admin children
    },
    {
      path: '/projects',
      name: 'projects',
      meta: { layout: 'default', permission: ['scope.project_order_mediaplan'], requiresAuth: true },
      component: () => import('../views/Projects.vue'),
      props: (route) => ({
        commercial_type_ids: route.query.commercial_type_ids,
        agency_ids: route.query.agency_ids,
        advertiser_ids: route.query.advertiser_ids,
        channel_ids: route.query.channel_ids,
        project_id: route.query.project_id,
        order_id: route.query.order_id,
        mediaplan_id: route.query.mediaplan_id,
        selected_year: route.query.selected_year,
        month_id: route.query.month_id,
        placement_type_id: route.query.placement_type_id,
        is_list_view: route.query.is_list_view,
        project_name: route.query.project_name,
        date_from: route.query.date_from,
        date_to: route.query.date_to,
      }),
    },
    {
      // due to refactor
      path: '/projects/table',
      redirect: (to) => {
        return 'media-plans-summary';
      },
    },
    {
      path: '/media-plans-summary',
      name: 'mediaPlansSummary',
      meta: {
        layout: 'default',
        permission: ['mediaplan.summary'],
        requiresAuth: true,
      },
      props: (route) => ({
        //commercial_type_id: route.query.commercial_type_id,
        agency_id: route.query.agency_id,
        advertiser_id: route.query.advertiser_id,
        brand_id: route.query.brand_id,
        date_from: route.query.date_from,
        date_to: route.query.date_to,
        channel_id: route.query.channel_id,
        placement_type_id: route.query.placement_type_id,
        measurement_id: route.query.measurement_id,
        target_id: route.query.target_id,
        mediaplan_id: route.query.mediaplan_id,
      }),
      component: () => import('../views/MediaPlansSummary.vue'),
    },
    {
      path: '/automatic-booking',
      name: 'automaticBooking',
      meta: { layout: 'default', permission: ['scope.mediaplan_automatic_list'], requiresAuth: true },
      component: () => import('../views/AutomaticBooking.vue'),
      props: (route) => ({
        q: route.query.q,
        per_page: route.query.per_page,
        on_page: route.query.on_page,
        sort_mode: route.query.sort,
        channel_id: route.query.channel_id,
        date_from: route.query.date_from,
        date_to: route.query.date_to,
      }),
    },
    {
      path: '/broadcast',
      name: 'broadcast',
      meta: {
        layout: 'default',
        permission: ['scope.broadcasting_grid'],
        requiresAuth: true,
      },
      component: () => import('../views/Broadcast.vue'),
      props: (route) => ({
        channel_id: route.query.channel_id,
        date: route.query.date,
        measurement_id: route.query.measurement_id,
        target_id: route.query.target_id,
        commercial_type_id: route.query.commercial_type_id,
        block_type_id: route.query.block_type_id,
      }),
    },
    {
      path: '/broadcast-one-day',
      name: 'broadcast-one-day',
      meta: {
        layout: 'default',
        permission: ['broadcasting.channel_one_day'],
        requiresAuth: true,
      },
      component: () => import('../views/BroadcastOneDay.vue'),
      props: (route) => ({
        channel_id: route.query.channel_id,
        date: route.query.date,
        measurement_id: route.query.measurement_id,
        target_id: route.query.target_id,
        commercial_type_id: route.query.commercial_type_id,
        block_type_id: route.query.block_type_id,
        agency_id: route.query.agency_id,
        advertiser_id: route.query.advertiser_id,
        project_id: route.query.project_id,
        order_id: route.query.order_id,
        mediaplan_id: route.query.mediaplan_id,
        brand_id: route.query.brand_id,
        placement_type_id: route.query.placement_type_id,
        time_slot: route.query.time_slot,
        sort_mode: route.query.sort,
      }),
    },
    {
      path: '/booking',
      name: 'booking',
      meta: {
        layout: 'default',
        permission: ['scope.booking_grid'],
        requiresAuth: true,
      },
      component: () => import('../views/Booking.vue'),
      props: (route) => ({
        channel_id: route.query.channel_id,
        date: route.query.date,
        agency_id: route.query.agency_id,
        advertiser_id: route.query.advertiser_id,
        project_id: route.query.project_id,
        order_id: route.query.order_id,
        mediaplan_id: route.query.mediaplan_id,
        measurement_id: route.query.measurement_id,
        target_id: route.query.target_id,
        commercial_type_id: route.query.commercial_type_id,
        block_type_id: route.query.block_type_id,
      }),
    },
    {
      path: '/booking-one-day',
      name: 'booking-one-day',
      meta: {
        layout: 'default',
        permission: ['booking.channel_one_day'],
        requiresAuth: true,
      },
      component: () => import('../views/BookingOneDay.vue'),
      props: (route) => ({
        channel_id: route.query.channel_id,
        date: route.query.date,
        measurement_id: route.query.measurement_id,
        target_id: route.query.target_id,
        commercial_type_id: route.query.commercial_type_id,
        block_type_id: route.query.block_type_id,
        agency_id: route.query.agency_id,
        advertiser_id: route.query.advertiser_id,
        project_id: route.query.project_id,
        order_id: route.query.order_id,
        mediaplan_id: route.query.mediaplan_id,
        brand_id: route.query.brand_id,
        placement_type_id: route.query.placement_type_id,
        sort_mode: route.query.sort,
      }),
    },
    {
      path: '/marketing-grid',
      name: 'marketingGrid',
      meta: {
        layout: 'default',
        permission: ['scope.marketing_grid'],
        requiresAuth: true,
      },
      component: () => import('../views/MarketingGrid.vue'),
      props: (route) => ({
        channel_id: route.query.channel_id,
        date: route.query.date,
        measurement_id: route.query.measurement_id,
        target_id: route.query.target_id,
        commercial_type_id: route.query.commercial_type_id,
        block_type_id: route.query.block_type_id,
      }),
    },
    {
      path: '/matching-fact-import',
      name: 'matchingFactImport',
      meta: {
        layout: 'default',
        permission: ['grp_fact.bind', 'grp_fact.unbind'],
        requiresAuth: true,
      },
      component: () => import('../views/MarketingMatchingFact.vue'),
      props: (route) => ({
        channel_id: route.query.channel_id,
        date: route.query.date,
        queue: route.query.queue,
        advertiser_id: route.query.advertiser_id,
        brand_id: route.query.brand_id,
        show_filters: route.query.show_filters,
      }),
    },

    {
      path: '/commercials-list',
      name: 'commercialsList',
      meta: {
        layout: 'default',
        permission: ['commercial.read_list'],
        requiresAuth: true,
      },
      component: () => import('../views/Commercials.vue'),
      props: (route) => ({
        q: route.query.q,
        per_page: route.query.per_page,
        on_page: route.query.on_page,
        sort_mode: route.query.sort,
        advertiser_id: route.query.advertiser_id,
        brand_id: route.query.brand_id,
        search_type: route.query.search_type,
      }),
    },

    {
      path: '/reports',
      name: 'reports',
      meta: {
        layout: 'default',
        requiresAuth: true,
        permission: ['scope.reports'],
      },
      component: () => import('../views/Reports.vue'),
      props: (route) => ({
        report_type: route.query.report_type,
        commercial_type_id: route.query.commercial_type_id,
        agency_id: route.query.agency_id,
        agency_ids: route.query.agency_ids,
        advertiser_id: route.query.advertiser_id,
        advertiser_ids: route.query.advertiser_ids,
        brand_id: route.query.brand_id,
        brand_ids: route.query.brand_ids,
        date_from: route.query.date_from,
        date_to: route.query.date_to,
        channel_id: route.query.channel_id,
        channel_ids: route.query.channel_ids,
        subchannel_ids: route.query.subchannel_ids,
        rating_type: route.query.rating_type,
        with_wgrp: route.query.with_wgrp,
        placement_type_id: route.query.placement_type_id,
        measurement_id: route.query.measurement_id,
        target_id: route.query.target_id,
        year: route.query.year,
        time_interval: route.query.time_interval,
        inventory_or_budget: route.query.inventory_or_budget,
        project_id: route.query.project_id,
        day_interval: route.query.day_interval,
        time_filter: route.query.time_filter,
        day_filter: route.query.day_filter,
        time_start_at: route.query.time_start_at,
        time_end_at: route.query.time_end_at,
        program_releases_names: route.query.program_releases_names,
        grouping_type: route.query.grouping_type,
        spotFactTime: route.query.spot_fact_time,
        with_discount_details: route.query.with_discount_details,
      }),
    },
    {
      path: '/retro-bonus-discounts',
      name: 'retroBonusTable',
      meta: {
        layout: 'default',
        requiresAuth: true,
        permission: ['retro_bonus_discount.manage'],
        requiresEnv: ['ENABLE_RETROBONUS'],
      },
      component: () => import('../views/RetroBonusTable.vue'),
      props: (route) => ({
        holding_id: route.query.holding_id,
        year: route.query.year,
        advertiser_id: route.query.advertiser_id,
      }),
    },

    {
      path: '*',
      meta: { layout: 'default', title: '404 — Not found' },
      component: () => import('../views/NotFound.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresEnv) {
    if (CheckEnv.checkEnv(to.meta.requiresEnv)) {
      next();
    } else {
      next({ path: '/' });
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // check if user is authed
    const token = Vue.$cookies.get('token');
    if (!token) {
      // if unauthed
      next({
        path: '/',
        query: { redirect: to.fullPath },
      });
    } else {
      // if authed
      const permission = to.meta.permission;
      // Check if route has permissions in meta
      if (permission && to.matched.some((record) => record.meta.permission)) {
        // Check if user has permissions to navigate to that page
        if (CheckPermissions.checkPermissions(...permission)) {
          next();
        } else {
          // if no permission to access requested page
          Vue.notify({ type: 'error', title: 'No permissions', duration: 3000 });
          from.path ? next({ path: from.path }) : next({ path: '/' });
        }
      } else {
        // if no permissions control
        next();
      }
    }
  } else {
    // if auth isn't required
    next();
  }
});

export default router;
