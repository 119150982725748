<template>
  <div class="side-bar__list">
    <router-link
      v-for="voc in vocabularies"
      :key="voc.title"
      class="router-link mb-3 d-block text-decoration-none"
      active-class="danger-color current"
      :to="{ name: voc.linkName }"
    >
      {{ voc.title }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SideBar',
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
    }),
    vocabularies() {
      const links = [];
      if (this.$checkPermissions('agency.read_list')) links.push({ linkName: 'agencies', title: this.$t('sideBar.agencies') });
      if (this.$checkPermissions('channel.read_list')) links.push({ linkName: 'channels', title: this.$t('sideBar.channels') });
      if (this.FEATURES.SUBCHANNELS && this.$checkPermissions('sub_channel.read_list'))
        links.push({ linkName: 'subchannels', title: this.$t('subChannels.subchannels') });

      if (this.$checkPermissions('saleshouse.read_list')) links.push({ linkName: 'salehouses', title: this.$t('sideBar.salehouses') });
      if (this.$checkPermissions('advertiser.read_list')) links.push({ linkName: 'advertisers', title: this.$t('sideBar.advertisers') });
      if (this.$checkPermissions('brand_class.read_list')) links.push({ linkName: 'classes', title: this.$t('sideBar.classes') });
      if (this.$checkPermissions('measurement_company.read_list')) links.push({ linkName: 'measurements', title: this.$t('sideBar.measurements') });
      if (this.$checkPermissions('genre.read_list')) links.push({ linkName: 'genres', title: this.$t('sideBar.genres') });
      if (this.$checkPermissions('program.read_list')) links.push({ linkName: 'programs', title: this.$t('sideBar.programs') });
      if (this.$checkPermissions('commercial_version_type.read_list'))
        links.push({ linkName: 'commVerTypes', title: this.$t('sideBar.commercialVersionTypes') });
      if (this.$checkPermissions('discount_type.read_list')) links.push({ linkName: 'discountTypes', title: this.$t('sideBar.discountTypes') });
      if (this.$checkPermissions('premium_position_discount.read_list'))
        links.push({ linkName: 'premiumPositionDiscounts', title: this.$t('sideBar.premiumPositionDiscounts') });
      if (this.$checkPermissions('user.read_list')) links.push({ linkName: 'users', title: this.$t('sideBar.users') });
      if (this.$checkPermissions('commercial.read_list')) links.push({ linkName: 'commercials', title: this.$t('sideBar.commercials') });
      if (this.$checkPermissions('placement_prohibition.read_list'))
        links.push({ linkName: 'placementProhibition', title: this.$t('sideBar.placementProhibitions') });
      if (this.$checkPermissions('co_placement_prohibition.read_list'))
        links.push({ linkName: 'coPlacementProhibition', title: this.$t('sideBar.coPlacementProhibitions') });
      if (this.$checkPermissions('price_project.read_list')) links.push({ linkName: 'priceProjects', title: this.$t('sideBar.priceProjects') });
      if (this.$checkPermissions('global_stop.disable')) links.push({ linkName: 'miscellaneous', title: this.$t('sideBar.miscellaneous') });
      if (this.$checkPermissions('scope.logs')) links.push({ linkName: 'logs', title: this.$t('sideBar.logs') });
      if (process.env.VUE_APP_ENABLE_HOLDINGS == 'TRUE' && this.$checkPermissions('holding.read_list'))
        links.push({ linkName: 'holdings', title: this.$t('holdings.holdings') });
      if (process.env.VUE_APP_ENABLE_RETROBONUS == 'TRUE' && this.$checkPermissions('retro_bonus_scale.read'))
        links.push({ linkName: 'uploadRetroScale', title: this.$t('retroScale.retroScale') });
      return links;
    },
  },
};
</script>
