// VUEX DYNAMIC STORE MODULE
import Vue from 'vue';
import { getLogs, getLogId, getNextLogs } from '../../api/apiServices';

export default {
  state: {
    logsList: null,
    logsListStatus: '',

    logData: null,
    logDataStatus: '',
  },
  mutations: {
    setLogsListData(state, payload) {
      state.logsListStatus = payload[0];
      Vue.set(state, 'logsList', payload[1] || null);
    },

    setNextLogsListData(state, payload) {
      state.logsListStatus = payload[0];
      const logsList = payload[1];
      if (logsList && Array.isArray(logsList?.data)) {
        if (Array.isArray(state.logsList?.data) && state.logsList?.data.length) {
          logsList.data = state.logsList.data.concat(logsList.data)
          Vue.set(state, 'logsList', logsList);
        }
      }
    },

    setLogData(state, payload) {
      state.logDataStatus = payload[0];
      Vue.set(state, 'logData', payload[1] || null);
    },

    clearLogs: (state) => {
      state.logsList = null;
      state.logsListStatus = '';
      state.logData = null;
      state.logDataStatus = '';
    },
  },
  actions: {
    // LOGS
    async GET_LOGS({ dispatch, commit }, payload) {
      commit('setLogsListData', ['loading']);
      await getLogs(payload.formData)
      .then((res) => {
        commit('setLogsListData', ['success', res.data]);
        if (typeof(payload.handler) === 'function') {
          payload.handler(res);
        }
      })
      .catch((err) => {
        commit('setLogsListData', ['error']);
        if (typeof(payload.handlerError) === 'function') {
          payload.handlerError(err);
        }
      });
    },

    async GET_NEXT_LOGS({ dispatch, commit }, payload) {
      await getNextLogs(payload.next_page_url, payload.formData)
      .then((res) => {
        commit('setNextLogsListData', ['success', res.data]);
        if (typeof(payload.handler) === 'function') {
          payload.handler(res);
        }
      })
      .catch((err) => {
        commit('setNextLogsListData', ['error']);
        if (typeof(payload.handlerError) === 'function') {
          payload.handlerError(err);
        }
      });
    },

    async GET_LOG_ID({ dispatch, commit }, payload) {
      commit('setLogData', ['loading']);
      await getLogId(payload.id, payload.params)
        .then((res) => commit('setLogData', ['success', res.data]))
        .catch((e) => commit('setLogData', ['error']));
    },
  },
  getters: {
    getLogsList: (state) => state.logsList,
    getLogData: (state) => state.logData,
    getLogsListStatus: (state) => state.logsListStatus,
    getLogDataStatus: (state) => state.logDataStatus,
  },
};
